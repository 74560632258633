<template>
  <div>
    <!-- heads button -->
    <div class="heads">
      <el-button class="add" @click="addBrand()">
        <i class="el-icon-plus"></i>
        {{ $t("common.add") }}
      </el-button>
      <div class="clear"></div>
    </div>
    <!-- picture -->
    <el-card class="role">
      <el-row>
        <el-col :span="2" class="p-left">
          <span>{{ $t("brand.logo") }}</span>
        </el-col>
        <el-col :span="16" class="p-left">
          <span>{{ $t("brand.name") }}</span>
        </el-col>
        <el-col :span="4" align="center">
          <span>{{ $t("brand.time") }}</span>
        </el-col>
        <el-col :span="2" align="center">
          <span>{{ $t("common.operate") }}</span>
        </el-col>
      </el-row>
    </el-card>

    <el-card
      class="mt1 headquarters"
      v-for="(item, index) in data"
      :key="index"
    >
      <el-row>
        <el-col :span="2" class="mt1 p-left">
          <img
            :src="item.img + '?x-oss-process=style/w128'"
            class="img"
            v-if="item.img != ''"
          />
          <img
            src="@/assets/default_brand_logo.png"
            class="img"
            v-if="item.img == ''"
          />
        </el-col>
        <el-col :span="16" class="mt p-left">
          <p>{{ item.brandName }}</p>
        </el-col>
        <el-col :span="4" class="mt"  align="center">
          <span>{{ item.createdAt }}</span>
        </el-col>
        <el-col :span="2" class="mt1"  align="center">
          <button class="p1" @click="edit(item._id)">
            {{ $t("common.edit") }}
          </button>
          <br />
          <button class="p2" @click="deletes(item._id)">
            {{ $t("common.delete") }}
          </button>
        </el-col>
      </el-row>
    </el-card>

    <div class="pagination-rounded" v-if="totalRows > perPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="perPage"
        layout="total, prev, pager, next, jumper"
        :total="totalRows"
        jumper-label="dd"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible="editDialog"
      @close="editDialog = false"
      width="40%"
    >
      <el-form
        label-width="100px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item prop="brandName" :label="$t('brand.name')">
          <el-input v-model="ruleForm.brandName"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="logo" :label="$t('brand.logo')">
                    <input
                    type="file"
                    ref="upload"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/png,image/jpg"
                    @change="freshImg"
                    />

                    <img
                    src="@/assets/default_brand_logo.png"
                    v-if="logoUrl == ''"
                    @click="uploadImg"
                    class="fl ml"
                    />
                    <img
                        :src="logoUrl"
                        v-if="logoUrl != ''"
                        @click="uploadImg"
                        class="fl ml avatar"
                        />
                </el-form-item> -->
        <el-form-item prop="logo" :label="$t('brand.logo')">
          <img
            src="@/assets/default_brand_logo.png"
            v-if="this.ruleForm.logo == ''"
            class="fl ml"
          />
          <el-image
            v-else
            :src="this.ruleForm.logo + '?x-oss-process=style/w128'"
            style="width: 100px; height: 100px"
          ></el-image>
        </el-form-item>
        <MaterialSelector
          :count="1"
          type="image"
          style="margin-left: 50px"
          @receiver="handleSelectionResult($event, 1)"
        >
        </MaterialSelector>
      </el-form>

      <div class="dialog-footer">
        <el-button @click="editDialog = false">{{
          $t("common.close")
        }}</el-button>
        <el-button type="primary" @click="doneData">{{
          $t("common.done")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createData,
  deleteData,
  getData,
  getLists,
  updateData,
} from "../../helper/api";
import MaterialSelector from "@/components/MaterialSelector";
const moment = require("moment");

export default {
  components: {
    MaterialSelector,
  },
  data() {
    return {
      status: 0,
      data: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageSizes: [1, 2, 3, 4, 5],
      editDialog: false,
      dialogTitle: "",
      editType: 0,
      brandId: "",
      logoUrl: "",
      ruleForm: {
        brandName: "",
        logo: "",
      },
      rules: {
        brandName: [
          {
            required: true,
            message: this.$t("brand.nameRequired"),
            trigger: "blur",
          },
        ],
        logo: [
          {
            required: true,
            message: this.$t("brand.logoRequired"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.brandList(this.currentPage);
  },
  methods: {
    handleSelectionResult(result, type) {
      // console.log("result, type:", result, type);
      if (type === 1) {
        // 主图
        this.ruleForm.logo = result.filename;
        // this.gallery = [...gallery];
        // console.log("this.ruleForm.logo:", this.ruleForm.logo);
      }
    },
    // 删除
    deleteImage(index, type) {
      // console.log("deleteImage-index:", index, "type:", type);
      if (type === 1) {
        // console.log("this.gallery", this.gallery);
        this.gallery.splice(index, 1);
      }
      if (type === 2) {
        // console.log("this.detailImages", this.detailImages);
        this.detailImages.splice(index, 1);
      }
    },
    brandList(page) {
      getLists("get", "brand", page, "desc").then((res) => {
        this.data = [];
        res.data.rows.forEach((item) => {
          item.createdAt = moment(item.createdAt).format("YYYY/MM/DD HH:mm");
          this.data.push(item);
        });
        // console.log("this.data:",this.data);
        // this.logo=this.data[0].img
        this.totalRows = res.data.count;
      });
    },
    doneData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.editDialog = false;
          if (this.editType == 1) {
            updateData("PUT", "brand", this.brandId, {
              img: this.ruleForm.logo,
              brandName: this.ruleForm.brandName,
            }).then((res) => {
              this.$message({
                message: res.success
                  ? this.$t("brand.brandUpdateSuccess")
                  : this.$t("brand." + res.message),
                type: res.success ? "success" : "error",
              });
              this.brandList(this.currentPage);
            });
          } else {
            createData("POST", "brand", {
              img: this.ruleForm.logo,
              brandName: this.ruleForm.brandName,
            }).then((res) => {
              if (res.success == true) {
                this.$message({
                  message: this.$t("brand.brandUpdateSuccess"),
                  type: "success",
                });
                this.brandList(this.currentPage);
              } else {
                if (res.message === "This Brand is already exist.") {
                  this.$message({
                    message: this.$t("brand.This Brand is already exist"),
                    type: "error",
                  });
                }
              }
            });
          }
        }
      });
    },
    addBrand() {
      this.editDialog = true;
      this.dialogTitle = this.$t("common.add");
      this.editType = 0;
      this.brandId = "";
      this.ruleForm.brandName = "";
      this.ruleForm.logo = "";
    },
    edit(id) {
      this.editDialog = true;
      this.dialogTitle = this.$t("common.edit");
      this.editType = 1;
      this.brandId = id;

      getData("GET", "brand", this.brandId).then((res) => {
        // console.log("edit:", res);
        this.ruleForm.brandName = res.data.brandName;
        this.ruleForm.logo = res.data.img;
        this.logoUrl = res.data.img;
      });
    },

    deletes(id) {
      this.$confirm(this.$t("common.confirmDelete"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.ok"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      })
        .then(() => {
          deleteData("DELETE", "brand", id).then(() => {
            this.$message({
              message: this.$t("common.deleteSuccess"),
              type: "success",
            });

            this.brandList(this.currentPage);
          });
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage = 1;
      this.brandList(this.currentPage);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.brandList(this.currentPage);
    },
  },
};
</script>

<style scoped>
.heads .button {
  font-size: 13px;
  width: 105px;
  height: 38px;
  line-height: 18px;
  float: left;
}
.heads .button img {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 2px;
  margin-left: -5px;
}
.heads .add {
  background: #ff0a0a;
  color: #fff;
  width: 120px;
  height: 40px;
  font-size: 14px;
  float: right;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
}
.heads i {
  margin-right: 7px;
}
.role {
  background: #ffdcbc;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.role .picture {
  padding-left: 15px;
}
.headquarters {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.headquarters .delete {
  color: #ff0a0a;
}
.headquarters .p1 {
  color: #ff0a0a;
  background: none;
  cursor: pointer;
}
.headquarters .p2 {
  margin-top: 5px;
  color: gray;
  background: none;
  cursor: pointer;
}
.p-left {
  text-align: left;
}
.p-center {
  text-align: center;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.heads-span {
  font-size: 16px;
}
p {
  font-size: 14px;
}
.dialog-footer {
  margin-top: 20px;
  text-align: right;
}
.ml {
  height: 60px;
  width: 60px;
}
.pagination-rounded {
  height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}
.logo {
  display: flex;
  justify-content: flex-start;
}
</style>